@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:36px;
	letter-spacing:0px;
	text-transform:inherit;
	font-family: 'Open Sans', sans-serif;
	font-weight:bold;
	color:#02508C;
    line-height: 1.5
}
h2 {
	font-size:32px;
	letter-spacing:0;
	text-transform: none !important;
	line-height:1.5;
	font-family: 'Open Sans', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:bold;
	line-height:1.5;
	font-family: 'Open Sans', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Open Sans', sans-serif;
}
h6 {
	font-size:22px;
	letter-spacing:0px;
    line-height:1.5;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Open Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#02508C;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#fff;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #02508C !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #02508C;
	color: #02508C;
	font-family: 'Open Sans', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #fff;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #02508C;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #02508C;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #02508C;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}
.links > li > a:hover {
	background-color: #fff;;
	color: #02508C !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #fff;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #02508C;
}
#nav .nav-panel--dropdown a {
	color: #02508C;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #02508C;
}
.mobnav-trigger.active {
	background-color: #02508C;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #02508C;
}
#nav .nav-item--home .fa-home {
	color: #02508C !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #02508C;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #fff;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #02508C;
	border-right-color: #02508C;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #02508C !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 18px;
	margin-top: 10px;
    height: 40px;
    line-height: 40px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #02508C !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
    color:#000;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 14px;
	letter-spacing: 0;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 10px 4px 0;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 16px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #02508C;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
}
button.button span {
	font-size: 18px;
}
button.button span span {
	padding: 0 24px;
    height: 40px;
    line-height: 40px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #02508C !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 16px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 16px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #fff;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #fff;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
    display:none;
}


/************************** MICS **************************/


.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
}
.header-top-container .module-header-multi .item-welcome-msg{
     margin-left:0;
}
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     height:32px;
     line-height:32px;
}
.top-header-container .module-header-multi p{
     margin-bottom:0 !important;
     height:32px;
     line-height:32px;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links>li>a, .userSwitch a, .welcome-msg {
     font-size:16px;
     color:#02508C !important;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links>li>a {
     padding-left:0;
     text-transform:inherit;
     font-size:16px;
}
.top-header-container .links > li:not(:first-child) > a:after {
     content:"|";
     margin-left:0.5833em;
}
.module-user-links .links .link-account::before {
     content:"My";
}
.module-user-links .links>li>a:before{
     font-family:"Open Sans";
     margin-right:3px;
}
.top-header-container .links {
     display:flex;
}
.top-header-container .links > li:nth-child(1) {
     order:3;
}
.top-header-container .links > li:nth-child(2) {
     order:2;
}
.top-header-container .links > li:nth-child(3) {
     order:1;
}
.userSwitch a:hover {
     color:#02508C;
}
.userSwitch a {
     text-indent:-9999px;
     position:absolute;
}
@media only screen and (max-width:1299px) {
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
@media only screen and (min-width:1300px) {
     .userSwitch a::after {
         content:"(Switch customers)";
    }
     .userSwitch a::after {
         text-indent:0;
         float:left;
    }
}
.desktop-top-header .header-top-container, .mobile-header .header-top-container {
     background-color:#fff;
}
.std i {
     font-style:normal;
}
.desktop-top-header {
     padding:0;
}
.desktop-top-header .grid-container {
     display:flex;
}
.desktop-top-header .logo-column, .desktop-top-header .cart-column, .desktop-top-header .search-column {
     margin:auto 0;
}
.desktop-top-header .cart-column {
     flex:1;
     margin-right:1%;
}
.desktop-top-header .cart-column .price span{
     font-weight:700;
}
.desktop-top-header .module-search .form-search input::placeholder {
     color:#02508C !important;
     font-weight:400;
}
.module-search .form-search .button-search .fa {
     font-weight:600;
     color:#fff !important;
     background-color:#B72025;
}
.desktop-top-header .module-search .form-search .input-text {
     border-color:#02508C;
     background-color:#fff ;
     font-weight:400;
     font-size:16px;
}
@media only screen and (min-width:1500px) {
     .desktop-top-header .search-column .module-search.search-wrapper {
         width:90%;
    }
}
.desktop-top-header .cart-column .fa.fa-shopping-cart, .mobile-header .fa.fa-shopping-cart {
     display:none;
}
.desktop-top-header .feature-icon-hover {
     padding-right:0 !important;
}
.desktop-top-header .feature-icon-hover .caret {
     border-top-color:#02508C;
     opacity:1;
}
.desktop-top-header .cart-column img {
     max-width:28px;
}
.desktop-category-column ul#nav {
     background-color:#02508C;
}
.desktop-category-column .nav-regular li.level0>a {
     text-transform:inherit;
}
.desktop-category-column .nav-regular li.level0>a>span {
     font-weight:700;
     font-size:20px;
     color:#fff;
     text-transform:inherit;
}
.desktop-category-column .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
.desktop-category-column ul#nav {
     display:flex;
     justify-content:space-evenly;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#02508C;
}
#inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         right:0px;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:767px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (max-width:959px) {
     #header {
         border-bottom:2px solid #02508c;
    }
     .desktop-top-header, .desktop-top-header .cart-column, .desktop-top-header .cart-column .module-header-multi, .mobile-header .item-min-cart, .mobile-header .item-min-cart .module-header-multi {
         overflow:unset;
    }
     .logo-column img, .footer-logo-col img{
         max-width:180px;
    }
     .fa.fa-bars.userCode {
         margin-right:0;
    }
}
@media only screen and (max-width:1299px) {
     .top-header-container .links>li>a, .userSwitch a, .welcome-msg span, .desktop-top-header .module-search .form-search .input-text, .desktop-top-header .module-search .form-search .input::placeholder, .module-switch-customer.switch-customer-wrapper span{
         font-size:14px;
    }
     .desktop-category-column .nav-regular li.level0>a>span {
         font-size:18px;
    }
}
/*Mobile*/
@media only screen and (max-width:767px) {
     #header .grid-full {
         text-align:center;
    }
     #header .item {
         float:none;
    }
     .top-header-container, .mobile-header {
         margin-bottom:5px !important;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         overflow:hidden !important;
    }
     .mobile-header .grid-full.module-header-multi > div{
         margin:auto;
    }
     .mobile-header .fa.fa-bars, .mobile-header .fa.fa-vcard, .mobile-header .item-search:not(.fly-out) .fa.fa-search {
         display:none !important;
    }
     .mobile-header .header .logo-wrapper {
         text-align:left !important;
         margin:0 !important;
    }
     .mobile-header #mini-cart .empty {
         display:none;
    }
     .mobile-header .logo img {
         max-width:40px;
    }
     .module-switch-customer.switch-customer-wrapper span {
         font-size:16px;
    }
     .userSwitch a::after {
         font-size:18px;
    }
     .mobile-header .mh-account-btn img, .mobile-header .mh-category-btn img, .mobile-header .button-search img, .mobile-header #mini-cart .hide-below-960 img {
         width:24px;
    }
     .mobile-header .mh-category-btn img {
         margin-top:3px;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:14px !important;
         color:#02508c !important;
         border-color:#02508c;
         width:99%;
    }
     /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa, .mobile-header .item-search.fly-out .fa.fa-search {
         font-size:16px;
         color:#02508c !important;
         padding:8px;
         font-style:normal;
         background-color:#fff;
         margin-top:2px;
    }
     .mobile-header .item-search.fly-out .button-search img {
         display:none;
    }
     /*.mobile-header .item-search.fly-out .ajax-search-flyout {
         border-radius:5px;
         background-color:#EFEAEA;
         color:#363636;
    }
     */
}
/*Misc*/
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
#header {
     position:sticky;
     top:0;
     z-index:50;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}
input.input-text, select, textarea {
     font-family:'Open Sans', sans-serif;
     font-size:16px;
     color:rgba(51,52,62,.85) !important;
     border-radius:0px;
}
input.input-text[disabled=disabled], input[disabled=disabled], input.input-text[disabled=disabled]::placeholder, input[disabled=disabled]::placeholder {
     color:#363636 !important;
     -webkit-text-fill-color:rgba(0, 0, 0, 0.8);
     -webkit-opacity:0.8;
}
/**/

.inline-search-results-quickorder .highlight{
     color:#2B3187 !important
}
span.item-secondary-heading {
     display:none!important;
}
.cart-action-buttons button {
     margin:20px 10px;
}
 .cart-summary-col {
     float:right;
}
 .cart-summary-col{
     margin-top:30px;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .cart-title .nested-container, .cart-table .nested-container, .cart-action-col .nested-container {
         width:100%;
    }
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
     .cart-action-buttons button{
         margin:10px;
    }
}
@media only screen and (min-width:1024px){
     .cart-summary-col{
         width:40%;
    }
}
@media only screen and (min-width:1600px){
     .cart-summary-col{
         width:30%;
    }
}
body.cart .data-table:not(.cart-table) .col-img img {
     width:60%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     body.cart .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
#shopping-cart-table h2.product-name .item-code {
     display:none;
}
/**/
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:40px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
     list-style:none;
     justify-content:center;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     padding:0px 20px;
     line-height:36px;
     height:36px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li.selected-navitem a, .account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover a{
     border-bottom:2px solid #02508C;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     padding:0;
     font-size:22px;
     color:#02508C;
     text-transform:Capitalize;
     line-height:36px;
     height:36px;
     transition:color 0.0s ease;
     font-weight:700;
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
#order-table, #transhistory-table, #address-book-table{
     margin-top:20px;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child a{
     display:block;
     width:90px;
     text-align:center;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child span{
     display:none;
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
.buttons-set.module-order-details-actions .back-link {
     margin-bottom:1px;
}
.buttons-set.module-order-details-actions .fa-angle-double-left:before {
     content:" " !important;
}
.order-info-box h2 {
     font-size:28px !important;
     line-height:30px;
}
.order-info-box .box-content {
     margin-top:25px;
}
.addresses-list h3 {
     font-weight:700;
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .order-info-box.module-quote-details-billing, .order-info-box.module-order-quote-shipping, .billing-module, .shipping-module{
         min-height:278px;
    }
     .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:300px;
    }
     .module-order-details-misc > div {
         margin-left:5px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
a.button span span, .account-view button.button.btn-inline span span {
     padding:0 12px;
     font-size:18px;
     font-weight:500;
     height:40px!important;
     line-height:40px!important;
     display:inline-block;
     background-color:#02508C ;
}
a.button span, .account-view button.button.btn-inline span {
     color:#fff;
     border-radius:0;
}
.account-view a.button:hover span, .module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover, .account-view button.button.btn-inline:hover span {
     background-color:transparent !important;
}
.account-view a.button:hover span span, .module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover, .account-view button.button.btn-inline:hover span span{
     background-color:#02508C !important;
}
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc .my-account .pager p.amount, .po-invc .filter_order_search_label, .po-invc .my-account > p {
         font-size:12px !important;
    }
     .po-invc #form_filter_user_data input {
         width:100px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
     .po-invc button.button span {
         font-size:16px;
    }
     #my-invoices-table, #my-orders-table {
         overflow:hidden;
    }
}
.module-trans-details-misc .item-options dd span {
     line-height:2px;
}
/*Login section*/
.login-page-title .page-title h1 {
     text-align:center;
}
.login-page-title .page-title .company-name {
     display:none;
}
.login-section h2, .register-section h2 {
     margin-bottom:15px;
     color:#02508C ;
     border-bottom:1px solid #000;
     line-height:34px;
     font-size:22px;
     font-weight:700;
}
.login-section ul.form-list li {
     margin-bottom:15px;
}
.login-section ul.form-list li label {
     margin-bottom:7px;
}
.login-section p.required {
     display:none;
}
.login-section .buttons-set {
     display:flex;
     flex-direction:column-reverse;
     margin-left:0;
}
.login-section .buttons-set > a{
     margin:15px 0;
}
.register-section .buttons-set {
     margin-left:0;
}
.register-section .buttons-set > button {
     float:left;
}
@media only screen and (min-width:960px) {
     .login-section.grid12-6, .register-section.grid12-6{
         width:38%;
    }
     .login-register-form .nested-container .grid-container {
         display:flex;
         justify-content:space-between;
    }
}
@media only screen and (min-width:1280px) {
     .login-section.grid12-6, .register-section.grid12-6{
         width:30%;
    }
}
@media only screen and (min-width:1536px) {
     .login-register-form .nested-container {
         width:85%;
    }
}
@media only screen and (min-width:1800px) {
     .login-register-form .nested-container {
         width:70%;
    }
}
@media only screen and (max-width:959px) {
     .login-register-form .nested-container, .login-page-title .nested-container{
         width:100%;
    }
     .login-section.grid12-6, .register-section.grid12-6{
         width:98%;
    }
     .login-section {
         margin-top:40px;
    }
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
     .login-section h2, .register-section h2 {
         font-size:20px;
    }
     .select2.select2-container.select2-container--default {
         width:100% !important;
         margin-bottom:15px;
    }
}
/*Transaction details*/
.page-title.module-trans-details-status h1 span, .page-title.module-order-details-status h1 {
     font-size:32px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     padding:10px;
     background-color:#02508C ;
     cursor:pointer;
     color:#fff;
     border-radius:0;
     border:none;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:70px;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .xm-grid-account .grid-container-wrapper {
         overflow:unset;
    }
     .fa-bars:after {
         color:#fff !important;
    }
}
@media only screen and (max-width:959px) {
     .page-title.module-trans-details-status h1 span {
         font-size:32px !important;
    }
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable p, .ReactTable span{
         font-size:11px !important;
    }
}
.due-input, .module-trans-details-actions .form-control {
     font-size:16px;
}
/*Formating changes on account pages*/
.ReactTable .rt-td a {
     text-decoration-color:#02508C !important;
     text-underline-offset:2px;
}
.ReactTable .rt-tbody .rt-tr.-odd:not(.-padRow) {
     background-color:rgba(160,168,177,.25) !important;
}
/*.ReactTable .rt-tbody .rt-tr.-even:not(.-padRow) {
     background-color:#DDDDDD !important;
}
*/
.ReactTable .-pagination .-btn {
     background-color:#02508C !important;
     color:#fff !important;
}
.ReactTable .pagination-bottom ._btn:disabled {
     opacity:0.5 !important;
}
.testunderlineDate {
     text-decoration:none !important;
}
.module-trans-details-misc .item-options dd span {
     color:#000;
}
/**/
.product-listing .nested-container {
     width:100%;
}
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
}
.sidebar-wrapper.module-category-filters .block.block-vertnav {
     margin-top:0;
}
.sidebar-wrapper.module-category-filters .block-title {
     font-weight:700;
     padding:0;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
     padding-left:0;
}
.product-listing {
     margin-top:0 !important;
}
.product-listing .add-cart-button span span{
     padding:0px !important;
}
.product-listing .page-title h1 {
     padding-top:0;
     padding-bottom:0px;
}
.product-search-container, .breadcrumbs-col .module-category-misc > div, #breadcrumbs li.home span:not(.breadcrumb-separator), .breadcrumbs-col .nested-container {
     margin-left:0 !important;
}
.breadcrumbs li span {
     text-transform:inherit;
     color:#000;
}
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager li a, .pager strong{
     font-size:16px;
}
.pager .pages li a {
     background-color:#AEAEB2;
     color:#000;
}
.pager .pages li.current {
     background-color:transparent;
     color:#000;
}
.pager .pages li a:hover {
     background-color:transparent;
     color:#000 !important;
}
.sorter .view-mode span.grid, .sorter .view-mode span.line {
     background-color:transparent;
}
.sorter .view-mode label {
     display:none;
}
.category-products-grid .item {
     border:none;
     margin-bottom:30px;
}
.item .module-category-product-listing h2.product-name {
     margin-bottom:5px;
     text-align:left;
     min-height:50px;
}
.products-grid.single-line-name .product-name {
     display:-webkit-box;
     -webkit-line-clamp:2;
     -webkit-box-orient:vertical;
     overflow:hidden;
     text-overflow:ellipsis;
     white-space:normal;
}
.item .module-category-product-listing h2.product-name a, .item .module-category-product-listing .price-box span{
     color:#000;
     font-size:18px;
     font-weight:600;
}
@media only screen and (max-width:1299px) {
     .item .module-category-product-listing h2.product-name a, .item .module-category-product-listing .price-box span{
         font-size:16px;
    }
}
/*Manage the height of the elements*/
.products-grid .product-image-wrapper {
     height:auto !important;
}
/*Full width button*/
.products-grid.centered .item {
     padding-left:0;
     padding-right:0;
     margin-left:1%;
     margin-right:1%;
     padding-top:0;
}
.item .module-category-product-listing .add-cart-wrapper {
     width:98% !important;
     margin-left:1%;
     margin-right:1%;
}
.item .module-category-product-listing .add-cart-wrapper > button, .item .module-category-product-listing .add-cart-wrapper > button span {
     width:100%;
     max-width:100%;
}
.toolbar-section .module-category-misc {
     display:flex;
     justify-content:space-between;
     align-items:baseline;
     flex-wrap:wrap;
}
.toolbar-section.pagination-bottom .module-category-misc {
     display:flex;
     justify-content:end;
}
@media only screen and (min-width:768px) {
     .item .module-category-product-listing .add-cart-wrapper {
         display:flex;
         justify-content:center;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
         margin:auto 10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > button{
         margin:auto;
         margin-left:10px;
    }
}
.module-category-product-listing .add-cart-wrapper input.qty {
     height:40px;
     border:1px solid #02508C;
}
.products-line .module-category-product-listing .module-category-product-listing__actionButtons .qty-wrapper input.qty {
     width:3em !important;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12, .breadcrumbs-col, .breadcrumbs-col .grid-column-wrapper {
         overflow:hidden;
    }
     .item .module-category-product-listing .item-code-label {
         display:none;
    }
     .mobile-filters .fa-sliders:before {
         font-size:22px;
         font-weight:400;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.6);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
     .products-grid .item button.button span span {
         padding:0px !important;
         height:40px !important;
         line-height:40px;
    }
     .pager .pages li {
         display:inherit;
    }
     .button.quickviewbutton {
         display:none;
    }
     .item .module-category-product-listing h2.product-name, .module-category-product-listing__price .price-box{
         text-align:center;
    }
}
.category-products-listing{
     margin-top:40px;
}
/* Quick View Dialog*/
#quick-view-modal .modal-footer {
     border-top:none;
}
#quick-view-modal button.button span span {
     max-width:100px;
     padding:0 40px;
}
#quick-view-modal button.button span {
     border-radius:0px;
}
.quick-view-modal-dialog .product-view .btn-cart span span {
     padding:0 20px !important;
}
.quick-view-modal-dialog .button.pull-right {
     color:#000;
}
/**/
.owl-theme .owl-dots .owl-dot span {
     width:12px !important;
     height:12px !important;
     background:transparent !important;
     border:1px solid #fff !important;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
     background:#fff !important;
}